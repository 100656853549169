export const ProcessEnv = {
  aws: {
    domain: process.env.REACT_APP_DOMAIN || '',
    region: process.env.REACT_APP_AWS_REGION || '',
    userPool: {
      id: process.env.REACT_APP_AWS_USER_POOL_ID || '',
      webClient: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID || '',
      mandatorySignIn: process.env.REACT_APP_AWS_AUTH_MANDATORYSIGNIN || '',
    },
    identityPool: {
      id: process.env.REACT_APP_AWS_IDENTITY_POOL_ID || '',
    },
    s3: {
      buckets: {
        company: process.env.REACT_APP_AWS_S3_COMPANY_BUCKET || '',
        loans: process.env.REACT_APP_AWS_S3_LOANS_BUCKET || '',
        media: process.env.REACT_APP_AWS_S3_MEDIA_BUCKET || '',
      },
    },
    api: {
      base: process.env.REACT_APP_API_BASE_URL || '',
      name: process.env.REACT_APP_API_NAME || '',
    },
    server_api: {
      baseUrl: process.env.REACT_APP_SERVER_API_BASE_URL || '',
    },
    socket: process.env.REACT_APP_SOCKET || '',
  },
  captcha: {
    siteKey: process.env.REACT_APP_CAPTCHA_SITE_KEY,
  },
};

// shorter const as it's often used
export const IDLE_TIME = Number(process.env.REACT_APP_LOGOUT_IDLE_TIME) || 3600000;

import * as Yup from 'yup';
import { defineApi } from '../common/api-definition';
import { StructuredLoanState } from '../structured-loan/structured-loan-state';
import { ObjectStripUnknown, RequiredDate } from '../validation/atoms';
import { literal, sumTypeRequired } from '../validation/base';
import { EventViewRequestValidation } from '../validation/EventViewRequestValidation';
import { EventViewRequest } from './EventViewRequest';
import {
  LCECovenant,
  LCECovenantCollection,
  LCECovenantDocument,
  LCECovenantInterval,
  LCEMetric,
} from './lce-covenants';

export type CovenantCollectionViewRequest =
  | {
      type: 'next-command-or-event';
      loanId: string;
      covenantId: string;
      checkDate: string;
    }
  | EventViewRequest;

export const covenantCollectionViewRequestValidation = sumTypeRequired({
  'next-command-or-event': ObjectStripUnknown({
    type: literal('next-command-or-event'),
    loanId: Yup.string().required(),
    covenantId: Yup.string().required(),
    checkDate: RequiredDate(),
  }),
  event: EventViewRequestValidation,
});

export type CovenantCollectionView = NextCovenantCollectionView | PastCovenantCollectionView;

export type NextCovenantCollectionView = CommonCovenantCollectionView & {
  type: 'next';
};

export type PastCovenantCollectionView = CommonCovenantCollectionView & {
  type: 'past';
  eventId: string;
  documents: LCECovenantDocument[];
};

export type CommonCovenantCollectionView = {
  loanId: string;
  version: number;
  state: StructuredLoanState;
  covenant: LCECovenant;
  interval: LCECovenantInterval;
  metrics: { [metricId: string]: LCEMetric };
  facilities: { [facilityId: string]: { name: string; legalEntityName?: string } };
  sequences: { [sequenceId: string]: { name: string } };
  properties: { [propertyId: string]: { name?: string } };
  collections: LCECovenantCollection[];
  toggleMap: { [tag: string]: boolean };
  replacementMap: { [tag: string]: string | undefined };
  notificationLenders: { id: string; name: string }[];
};

export const covenantCollectionViewApi = defineApi<{
  body: CovenantCollectionViewRequest;
  response: CovenantCollectionView;
}>({
  url: 'structured-loans/covenant-collection-view',
  method: 'post',
});

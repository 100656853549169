/**
 * The built-in/standard/computed metrics
 */
import { includes } from 'lodash';
import { Option } from '../common/Option';
import {
  ConsequenceDefType,
  LoanMetricClassType,
  LoanMetricPeriodType,
  LoanMetricType,
  LoanMetricUnitType,
  ProofDocumentType,
  QualificationsType,
} from './LoanMetricType';

export const builtinMetricIds = ['outstanding-debt', 'interest-amount', 'amortisation'] as const;
export type BuiltinMetricId = (typeof builtinMetricIds)[number];
export const isBuiltinMetricId = (id: string): id is BuiltinMetricId => includes(builtinMetricIds, id);
export const isBuiltinMetric = (metric: LoanMetricType): metric is BuiltinMetricType => {
  return isBuiltinMetricId(metric.id) && metric.source.type === 'calculated';
};

export type BuiltinMetricType = {
  id: BuiltinMetricId;
  name: string;
  description: string;
  definition: string;
  unit: LoanMetricUnitType;
  source: { type: 'calculated' };
  period: LoanMetricPeriodType;
  qualifications: QualificationsType;
  class: LoanMetricClassType;
  proofDocument: ProofDocumentType;
  consequences: ConsequenceDefType[];
  ccBinding: string;
  builtIn: true;
};

const outstandingDebt: BuiltinMetricType = {
  id: 'outstanding-debt',
  name: 'Outstanding Debt',
  description: 'The outstanding debt of the loan',
  definition: 'The principal minus the amortisation',
  unit: 'amount',
  source: { type: 'calculated' },
  period: { type: 'pit' },
  qualifications: 'audited_unaudited',
  class: 'balance_sheet',
  builtIn: true,
  proofDocument: 'none',
  ccBinding: 'outstanding_debt',
  consequences: [],
};

const interestAmount: BuiltinMetricType = {
  id: 'interest-amount',
  name: 'Interest',
  description: 'The interest paid on the loan',
  definition: 'The interest paid on the loan',
  unit: 'amount',
  source: { type: 'calculated' },
  period: { type: 'sot' },
  qualifications: 'audited_unaudited',
  class: 'balance_sheet',
  builtIn: true,
  proofDocument: 'none',
  ccBinding: 'interest_amount',
  consequences: [],
};

const amortisation: BuiltinMetricType = {
  id: 'amortisation',
  name: 'Amortisation',
  description: 'The amortisation of the loan',
  definition: 'The amount of money paid back on the principal',
  unit: 'amount',
  source: { type: 'calculated' },
  period: { type: 'sot' },
  qualifications: 'audited_unaudited',
  class: 'balance_sheet',
  builtIn: true,
  proofDocument: 'none',
  ccBinding: 'amortisation',
  consequences: [],
};

export const builtinMetrics = {
  amortisation,
  'interest-amount': interestAmount,
  'outstanding-debt': outstandingDebt,
};

export const builtInMetricsOptions: Option[] = Object.values(builtinMetrics).map(metric => {
  return {
    value: metric.id,
    label: metric.name,
    disabled: false,
  };
});

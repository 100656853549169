import { defineApi } from '../common/api-definition';
import { SuccessOrErrorType } from '../common/SuccessOrErrorType';
import { ValidationIssueType } from '../common/ValidationIssueType';
import { AmortisationType } from '../loan/AmortisationType';
import { DateRolling } from '../loan/DateRolling';
import { DayCountConventionType } from '../loan/DayCountConventionType';
import { InterestPeriodAlignmentType } from '../loan/InterestPeriodAlignmentType';
import { InterestPeriodType } from '../loan/InterestPeriodType';
import { InterestRateType } from '../loan/InterestRateType';
import { LCEInterestPeriodBasis } from '../loan/lce-interest-period-basis';
import { LifecycleRunLoanType } from '../loan/LifecycleRunLoanType';
import { MaturityType } from '../loan/MaturityType';
import { MortgageBondSchedule } from '../loan/MortgageBondSchedule';
import { SubventionType } from '../loan/SubventionType';

export type RunSimulationQuery = {
  terms: RunSimulationTerms;
  'base-rates'?: { [periodIndex: number]: number };
  'mortgage-bond-schedule'?: MortgageBondSchedule;
};

export type RunSimulationResponse = SuccessOrErrorType<
  RunSimulationResult,
  ValidationIssueType<LifecycleRunLoanType> | undefined
>;

export type RunSimulationTerms = RunSimulationInterestRateTerms | RunSimulationMortgageBondTerms;

export type RunSimulationInterestRateTerms = RunSimulationCommonTerms & {
  type: 'interest-rate';
  'day-count-convention'?: DayCountConventionType;
  'interest-rate'?: Partial<InterestRateType>;
  amortisation?: Partial<AmortisationType>;
  subvention?: Partial<SubventionType> | null;
};

export type RunSimulationMortgageBondTerms = RunSimulationCommonTerms & {
  type: 'mortgage-bond';
};

export type RunSimulationCommonTerms = {
  currency?: string;
  'loan-size'?: number;
  'business-day-countries'?: string[];
  'interest-rate-period'?: Partial<InterestPeriodType>;
  'interest-period-basis'?: LCEInterestPeriodBasis;
  'interest-period-alignment'?: InterestPeriodAlignmentType;
  'date-rolling'?: DateRolling;
  'maturity-payment-of-debt'?: Partial<MaturityType>;
  'utilisation-date'?: string;
  'agreement-signature-date'?: string;
  'min-interest-period-value-date'?: string;
  prepayments?: { [periodIndex: number]: number | undefined };
  meta?: { 'disable-schedule-matching': boolean };
};

export type RunSimulationResultInterestPeriod = {
  'period-index': number; // Periods are numbered 0, 1, ...
  'value-date': string;
  'outstanding-debt': number;
  interest?: number; // Only set if the interest period has an interest payment.
  amortisation?: number; // Only set if the interest period has an amortisation payment.
  prepayment?: number; // Only set if the interest period has a non-scheduled prepayment.
  'subvention-coverage'?: number; // Only set if the loan has subvention
};

export type RunSimulationResult = {
  'interest-periods': RunSimulationResultInterestPeriod[];
};

export const runSimulationApi = defineApi<{
  body: RunSimulationQuery;
  response: RunSimulationResponse;
}>({
  url: 'lifecycle/run-simulation',
  method: 'post',
});

import { defineApi } from '../common/api-definition';
import { LCESequenceTrancheSchedule, LCESequenceTrancheSchedules } from '../loan/lce-sequence-tranche-schedules';

export type SequencePaymentScheduleRequest = { loanId: string; sequenceId: string };

export type SequencePaymentScheduleResponse = {
  loanId: string;
  facilityId: string;
  sequenceId: string;

  loanName: string;
  facilityName: string;
  sequenceName: string;

  currentTranche: LCESequenceTrancheSchedule;
  trancheNames: { [id: string]: string };
  schedules: LCESequenceTrancheSchedules;
};

export const sequencePaymentScheduleApi = defineApi<{
  body: SequencePaymentScheduleRequest;
  response: SequencePaymentScheduleResponse;
}>({
  url: 'structured-loans/sequence-payment-schedule',
  method: 'post',
});

import { BookingType } from '../loan/BookingType';
import { formatDate } from './format-date';

export function bookingToDescription(booking: BookingType): string {
  switch (booking.type) {
    case 'pay-out-principal': {
      return 'Principal Payout';
    }
    case 'pay-out-principal-agent-fee': {
      return 'Principal Payout Agent Fee';
    }
    case 'pay-out-drawdown': {
      return 'Drawdown Payout';
    }
    case 'pay-out-drawdown-agent-fee': {
      return 'Drawdown Agent Fee';
    }
    case 'coupon-agent-fee': {
      return 'Coupon Agent Fee';
    }
    case 'direct-coupon':
    case 'paying-agent-coupon': {
      const { period } = booking.details['interest-payment-details'];
      const startDate = dateText(period['start-date']);
      const endDate = dateText(period['end-date']);
      return `Payment for the interest period from ${startDate} to ${endDate}`;
    }
    case 'direct-subperiod-prepayment':
    case 'paying-agent-subperiod-prepayment': {
      const date = dateText(booking['value-date']);
      return `Prepayment on ${date}`;
    }
    case 'direct-prepayment':
    case 'paying-agent-prepayment': {
      const date = dateText(booking['value-date']);
      return `Prepayment on IPD ${date}`;
    }
    case 'direct-pay-back-principal':
    case 'paying-agent-pay-back-principal': {
      return 'Principal Payback';
    }
    case 'paying-agent-coupon-subvention':
    case 'direct-coupon-subvention': {
      const { period } = booking.details['interest-payment-details'];
      const start = dateText(period['start-date']);
      const end = dateText(period['end-date']);
      return `Government Subvention for the period ${start} to ${end}`;
    }
    case 'processing-fee-coupon':
    case 'processing-fee-pay-back-principal':
    case 'processing-fee-subperiod-prepayment':
    case 'processing-fee-prepayment':
    case 'tax-withholding-coupon':
    case 'tax-withholding-subperiod-prepayment':
    case 'tax-withholding-prepayment':
    case 'indirect-coupon':
    case 'indirect-pay-back-principal':
    case 'indirect-subperiod-prepayment':
    case 'indirect-prepayment':
      return '';
  }
}

const dateText = (date: string) => formatDate(new Date(date));

import { EventType } from './EventType';

export const AllEventLabels: Record<EventType['type'], string> = {
  'create-loan': 'Create Loan',
  'create-structured-loan': 'Create Structured Loan',
  'amend-terms': 'Amend Terms',
  'amend-structured-loan': 'Amend Structured Loan',
  'sl-abort': 'Abort Loan',
  'base-rate': 'Base Rate Quotation',
  'mortgage-bond-coupon': 'Mortgage Bond Coupon',
  'initiate-drawdown': 'Initiate Drawdown',
  'initiate-prepayment': 'Initiate On-Cycle Prepayment',
  'initiate-subperiod-prepayment': 'Initiate Mid-Cycle Prepayment',
  'initiate-utilisation': 'Utilisation Request',
  'initiate-termination': 'End Construction Project',
  'interest-rate-adjustment': 'Interest Rate Adjustment',
  'metric-collection': 'Collection Event',
  'covenant-collection': 'Covenant Report',
  'reopen-metric-check-date': 'Reopen Collection Event',
  'sl-information-undertaking': 'Collection Event',
  'sl-covenant-report': 'Covenant Report',
  'issue-tokens': 'Issue Tokens',
  'move-tokens': 'Move Tokens',
  booking: 'Booking',
  transaction: 'Transaction',
  'late-transaction': 'Late Payment',
  sequence: 'Structured Loan Event',
  unwind: 'Unwind',
  'set-interest-rate': 'Amend Interest Rate',
  'set-amortisation': 'Amend Amortisation',
  'set-custom-booking-amount': 'Set Ad-Hoc Payment',
};

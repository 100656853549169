export const OpenAPIExceptionSchema = {
  schema: {
    type: 'object',
    properties: {
      id: { type: 'string' },
      message: { type: 'string' },
      timestamp: { type: 'string', format: 'date-time' },
      cause: {
        type: 'object',
        properties: {
          name: { type: 'string' },
          message: { type: 'string' },
        },
      },
    },
  },
};

export abstract class RealstocksException extends Error {
  public readonly id: string;
  public readonly timestamp: Date;

  constructor(public readonly message: string, public readonly cause?: string | Record<string, any>) {
    super();
    this.id = RealstocksException.genId();
    this.timestamp = new Date();
  }

  private static genId(length = 16): string {
    const p = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return [...Array(length)].reduce(a => a + p[~~(Math.random() * p.length)], '');
  }
}

export class ConflictException extends RealstocksException {}
export class UnauthenticatedException extends RealstocksException {}
export class InternalServerErrorException extends RealstocksException {}
export class NotFoundException extends RealstocksException {}
export class NotImplementedException extends RealstocksException {}
export class SftpException extends RealstocksException {}
export class UnauthorizedException extends RealstocksException {}
export class ValidationException extends RealstocksException {}

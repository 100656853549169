import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { RsErrorFallbackRaw } from './components/rs-error/RsErrorFallback';
import RsApplication from './RsApplication';
import store from './store';

Modal.setAppElement('#root');

// export const appHistory = createBrowserHistory();
let sentryInitialized = false;

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
  try {
    Sentry.init({
      environment: process.env.REACT_APP_SENTRY_ENV,
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      autoSessionTracking: true,
      ignoreErrors: ['Non-Error exception captured'],
      // integrations: [
      //   new Integrations.BrowserTracing({
      //     routingInstrumentation: Sentry.reactRouterV5Instrumentation(appHistory),
      //   }),
      // ],

      // Reduce for better performance (between 0 and 1.0)
      tracesSampleRate: 1.0,
    });
    sentryInitialized = true;
    console.log('SENTRY initialized', sentryInitialized);
  } catch (error) {
    console.error('Sentry error');
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {sentryInitialized ? (
        <Sentry.ErrorBoundary
          fallback={() => {
            return <RsErrorFallbackRaw />;
          }}
        >
          <RsApplication />
        </Sentry.ErrorBoundary>
      ) : (
        <RsApplication />
      )}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

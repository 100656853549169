type HttpMethod = 'post' | 'get' | 'delete' | 'put';

/**
 * A definition of an endpoint for communication between frontend and server.
 */
export type ApiDefinition = {
  /** Url of the endpoint. Use ":loanId" for parameters. Don't use a starting "/". */
  url: string;
  method: HttpMethod;
  /**
   * The parameters of the endpoint. The keys have to match parameters provided in the url.
   *
   * @deprecated Don't use this anymore. Just use a body instead. The problem is that routing
   *             this in Nestjs makes it so you need to have routes with params after the
   *             routes without params, as otherwise the later route gets picked up as a param.
   */
  params?: Record<string, string>;
  body?: object;
  /** The response has to be void or JSON-deserializable. If you need a scalar type, wrap it in an OutputDto. */
  response?: object | unknown[];
  /** True if the endpoint is in the serverless stack. Leave out otherwise. */
  serverless?: true;
};

export type Url<T extends ApiDefinition> = T['url'];
export type Method<T extends ApiDefinition> = T['method'];
export type Params<T extends ApiDefinition> = T['params'];
export type Body<T extends ApiDefinition> = T['body'];
export type Serverless<T extends ApiDefinition> = T['serverless'];
export type Response<T extends ApiDefinition> = T['response'];

type ApiDefinitionTypesOnly = Pick<ApiDefinition, 'body' | 'params' | 'response'>;
type DefineApiFn = <
  T extends ApiDefinitionTypesOnly,
  TUrl extends string = string,
  TMethod extends HttpMethod = HttpMethod,
  TServerless extends true = true
>(args: {
  /** Url of the endpoint. Use ":loanId" for parameters. */
  url: TUrl;
  method: TMethod;
  /** True if the endpoint is in the serverless stack. Leave out otherwise. */
  serverless?: TServerless;
}) => T & { url: TUrl; method: TMethod; serverless: TServerless };

/**
 * Define an endpoint for communication between frontend and server.
 *
 * ### Usage
 *
 * ```
 * const myEndpointDefinition = defineApi<{
 *   params: YourParamsType,
 *   body: YourBodyType,
 *   response: YourResponseType
 * }>({
 *   url: 'structured-loans/:loanId/close-reporting-event',
 *   method: 'post'
 * });
 * ```
 *
 * ### Notes
 *
 * We can also add all the endpoints to a list and throw if there is a
 * duplicate method+url+serverless.
 */
export const defineApi: DefineApiFn = ({ url, method, serverless }) =>
  ({
    url,
    method,
    serverless,
  } as any);

/**
 * Helper for getting the url part after the root.
 *
 * "structured-loans/do-something" becomes "do-something".
 */
export const urlSkipRoot = (definition: ApiDefinition): string =>
  definition.url.substring(definition.url.indexOf('/') + 1);

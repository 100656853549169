import { AssetUnderManagementType } from '../common/AssetUnderManagementType';
import { CompanyTypeType } from '../common/CompanyTypeType';
import { InvestorType } from '../common/InvestorType';
import { RsUploadedFileType } from '../common/RsUploadedFileType';
import { CompanyAddressType } from '../company/CompanyAddressType';
import {
  AgreementBlockType,
  AgreementCommentChainType,
  AgreementCommentMetaType,
  AgreementDocType,
} from '../document-assembly/AgreementType';
import { FaEnvelopeType, FaNdaEnvelopeType } from '../docusign/EnvelopeType';
import { EmbeddedSignerType, ExternalSignerType, ISigner } from '../docusign/SignerType';
import { BaseRateNameType } from '../loan/BaseRateNameType';
import { CovenantTestingDatesType } from '../loan/CovenantTestingDatesType';
import { DayCountConventionType } from '../loan/DayCountConventionType';
import { InterestPeriodType } from '../loan/InterestPeriodType';

export type InterestType = 'fixed' | 'floating';

export const AssetUnderManagementMap: Record<AssetUnderManagementType, string> = {
  1: 'Under 100 €m',
  2: '100 €m - 2.5 €bn',
  3: 'Above 2.5 €bn',
};

export const InvestorTypeMap: Record<InvestorType, string> = {
  1: 'Insurance Company',
  2: 'Pension Fund',
  3: 'Close Ended Fund',
  4: 'Open Ended Fund',
  5: 'Family Office / Investment Companies',
  6: 'Bank',
};

export type LoanBasicInfoPropertyType = {
  property_type: string | null;
  square_meters?: number;
  property_address: string | null;
  tenants: LoanPropertyTenantType[];
};

export type LoanPropertyTenantType = {
  tenant_name: string | null;
  tenant_rental_income?: number;
  tenant_rental_duration?: number;
};

export type NegotiationAttributesValuesType = Partial<AttributesType>;

export type NegotiationAttributesType<T> = {
  values: T;
  previousValues: Partial<T>;
  transitions: Partial<Record<keyof T, ITransition>>;
  accept: {
    agent: NegotiationAcceptType<T>;
    borrower: NegotiationAcceptType<T>;
    lender: NegotiationAcceptType<T>;
  };
  reject: {
    agent: NegotiationAcceptType<T>;
    borrower: NegotiationAcceptType<T>;
    lender: NegotiationAcceptType<T>;
  };
  comments?: NegotiationAttributesType<Record<string, AgreementCommentChainType[]>> & {
    // Map from attribute name to additional comment details.
    commentMetaMap: Record<string, AgreementCommentMetaType>;
  };
};

export type NegotiationAcceptType<T> = Partial<Record<keyof T, boolean>>;

export type LegalTermNegotiationStatusT = {
  borrower: boolean;
  lender: boolean;
};

export type NegotiationFilesType = { upload_material?: RsUploadedFileType[]; gallery?: RsUploadedFileType[] };

export type LoanRules = { [key: string]: any };

/**
 * Loan type
 */
export type NegotiationType = {
  id: string;
  version: string;
  transitionCounter: number;
  requestedBy: string;
  template: string;
  state: NegotiationStateType;
  legalTermsNegotiationStatus?: LegalTermNegotiationStatusT;
  rules: LoanRules;
  borrowerCompanyId: string;
  agent?: string;
  leadLenderCompanyId?: string;
  companiesMap: {
    borrower: LoanCompanyType;
    [companyId: string]: LoanCompanyType;
  };
  files: NegotiationFilesType;
  gallery: RsUploadedFileType[];

  lenderQuotes: LenderQuoteMapType;
  lenderQuotesIsOpen: boolean;
  shareAllocation: ShareAllocationType;
  signers?: SignersType;
  envelopeId?: string;
  agreement_signature_date?: string; // The formatted date used during document assembly.

  documentsSetup: DocumentsSetupType;
  ndaSetup?: NdaSetupType;

  securityAgentCompanyName: string;

  createdAt: DBTimeType;
  attributes: NegotiationAttributesType<NegotiationAttributesValuesType>;
  agreementNegotiation: NegotiationAttributesType<Record<string, AgreementDocType>> & {
    lock?: ITransition;
  };
  agreementCommentsNegotiation?: NegotiationAttributesType<Record<string, AgreementCommentChainType[]>> & {
    // Map from durable ID to additional comment details.
    commentMetaMap: Record<string, AgreementCommentMetaType>;
  };

  privateData?: PrivateDataType;

  // Data that is loaded dynamically.
  transitions?: LoadedData<TransitionType[]>;
  usersMap?: LoadedData<LoanUserMapType>;
  lastWebsocketUpdate: number | undefined;
};

export type LoadedData<T> = {
  isLoaded: boolean; // True if data is up to date.
  data: T;
};

export type DBDateType = string; // YYYY-MM-DD
export type DBTimeType = string; // ISO 8601 date

export const AllNegotiationStates = {
  request: 'Loan request',
  configuration: 'In configuration with loan agent',
  negotiation: 'In negotiation with lead lender',
  lenderQuotes: 'Open for additional lenders',
  signing: 'Ready for signature',
  execution: 'Review & Execution',
  canceled: 'Cancelled',
} as const;
export const LoanStatus = {
  request: 'request',
  configuration: 'configuration',
  negotiation: 'negotiation',
  lenderQuotes: 'lenderQuotes',
  signing: 'signing',
  execution: 'execution',
  canceled: 'canceled',
};
export type NegotiationStateType = keyof typeof AllNegotiationStates;

export const AllInterestTypes = {
  fixed: 'Fixed',
  floating: 'Floating',
} as const;

export const AllMaturityInYears: { [key: string]: MaturityInYearsType } = {
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  setDate: 'set-date',
};
export type MaturityInYearsType = '1' | '2' | '3' | '4' | '5' | 'set-date';

export const AllFacilityTypes = {
  termLoan: 'Term Loan',
};
export type FacilityTypeType = keyof typeof AllFacilityTypes;

export const AllAmortisationPartialPrepayment = {
  inverseChronological: 'Inverse chronological',
  chronological: 'Chronological',
} as const;
export type AmortisationPartialPrepaymentType = keyof typeof AllAmortisationPartialPrepayment;

export const AllHedgingInterestInstruments = {
  none: 'None',
  swap: 'Swap',
  cap: 'Cap',
} as const;
export type HedgingInterestInstrumentType = keyof typeof AllHedgingInterestInstruments;

export const AllRelevantMarkets = {
  stockholm: 'Stockholm Interbank Market',
  europe: 'European Interbank Market',
} as const;
export type RelevantMarketType = keyof typeof AllRelevantMarkets;

export const AllMarketValueEstBy = {
  crbe: 'CBRE',
  jll: 'Jones Lang Lasalle (JLL)',
  cushmanAndWakefield: 'Cushman & Wakefield',
  newsec: 'Newsec',
  colliers: 'Colliers',
  savills: 'Savills',
  catella: 'Catella',
  borrower: 'Borrower',
  others: 'Others',
} as const;
export type AllMarketValueEstByType = keyof typeof AllMarketValueEstBy;

export const AllTypesOfGuarantees = {
  onDemand: 'On-demand',
  corporateGuarantee: 'Corporate Guarantee',
  cashCollateral: 'Cash collateral',
  letterOfSupport: 'Letter-of-support (LoS)',
  letterOfIntent: 'Letter-of-Intent (LOI)',
};
export type TypeOfGuaranteeType = keyof typeof AllTypesOfGuarantees;

export const AllInterestRatePeriods = {
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  'semi-yearly': 'Half-Yearly',
  yearly: 'Yearly',
} as const;
export type InterestRatePeriodType = keyof typeof AllInterestRatePeriods;

export const AllLoanPropertyTypes = {
  office: 'Office',
  retail: 'Retail',
  residential: 'Residential',
  industrial: 'Industrial',
  logistics: 'Logistics',
  hotel: 'Hotel',
  care: 'Care',
  public_infrastructure: 'Public infrastructure',
  other: 'Other',
};
export type LoanPropertyTypeType = keyof typeof AllLoanPropertyTypes;

export type NdaSetupType = {
  template: string; // S3 key of template (or the empty string).
  workflowId: string; // ID verification for signers (or the empty string).
  isDisabled: boolean; // True if NDA signing is not required.
};

export type DocumentsSetupType = {
  // Map from category ID to category.
  categoriesMap: Record<string, DocumentCategoryType>;

  // Map from list ID to list.
  listsMap: Record<string, DocumentCategoryListType>;

  // IDs of lists to show on the documents page.
  listIds: string[];
};

export type DocumentCategoryType = {
  id: string;

  content: {
    // Transition for when the content was edited. No transition is present if
    // these are the initial contents.
    transition?: ITransition;
    value: AgreementBlockType[];
  };

  // The files that have been uploaded for the category.
  documents: RsUploadedFileType[];

  enabled: {
    // Transition for the enabling or disabling. Empty if this is the initial
    // value.
    transition?: ITransition;
    value: boolean; // True if the category is enabled.
  };

  approval: {
    // Transition for the approval. Empty if this is the initial value.
    transition?: ITransition;
    value: boolean; // True if the category is approved
  };
};

export type DocumentCategoryListType = {
  id: string;
  title: string;
  categoryIds: string[];
};

export type DecimalType = string;
export type CurrencyType = string;
export type PercentageType = string;
export type CountryType = string;

export type LegalOpinionType = {
  legal_opinion_law_firm: string;
  legal_opinion_country: CountryType;
};

export const TransitionLabels: Record<TransitionType['type'], string> = {
  accept_agreement: 'Accept Agreement',
  accept_attributes: 'Accept Attributes',
  reject_attributes: 'Reject Attributes',
  accept_request: 'Accept Request',
  cancel_facility_agreement: 'Cancel Negotiation',
  create_facility_agreement: 'New Request',
  release_for_lender_quotes: 'Release for Lender Quotes',
  release_for_signing: 'Release for Signing',
  restart_facility_agreement: 'Restart Negotiation',
  save_attributes: 'Update Attributes',
  set_agreement_lock: 'Change Agreement Lock',
  set_lead_lender: 'Set Lead Lender',
  set_agent: 'Set Agent',
  set_lender_quotes_is_open: 'Lender Quotes Is Open',
  set_share_allocation: 'Set Share Allocation',
  set_signers: 'Set Signers',
  set_template: 'Set Template',
  start_negotiation: 'Start Negotiation Phase',
  start_execution: 'Start Loan Life Cycle',
  update_agreement: 'Update Agreement',
  update_agreement_comments: 'Update Agreement Comments',
  add_documents: 'Add Documents to Category',
  update_document_category: 'Update Document Category',
  add_document_category: 'Add Document Category',
  get_nda_signing_url: 'Start NDA signing ceremony',
  signing_event: 'Signing Event',
  update_nda_setup: 'Update NDA settings',
  upload_file_event: 'Update file',
  update_fa_comments: 'Negotiation Comment',
  cancel_loan: 'Cancel Loan',
};

export interface ITransition {
  id: string;
  userId: string;
  companyType: CompanyTypeType;
  companyId: string;
  time: DBTimeType;
}

type TypedDataType<Type, Data> = {
  type: Type;
  data: Data;
};

export type UpdateDocumentCategoryType = { name: 'enabled'; value: boolean } | { name: 'approval'; value: boolean };

export type TransitionDataUnionType =
  | TypedDataType<'upload_file_event', undefined>
  | TypedDataType<'accept_request', undefined>
  | TypedDataType<'cancel_facility_agreement', undefined>
  | TypedDataType<'create_facility_agreement', { attributes: Partial<AttributesType> }>
  | TypedDataType<'release_for_lender_quotes', undefined>
  | TypedDataType<'release_for_signing', undefined>
  | TypedDataType<'restart_facility_agreement', undefined>
  | TypedDataType<
      'add_documents',
      {
        categoryId: string;
        documents: RsUploadedFileType[];
      }
    >
  | TypedDataType<
      'update_document_category',
      {
        categoryId: string;
        update: UpdateDocumentCategoryType;
      }
    >
  | TypedDataType<
      'add_document_category',
      {
        listId: string;
        content: AgreementBlockType[];
      }
    >
  | TypedDataType<'set_agent', { agent: string }>
  | TypedDataType<'set_lead_lender', { leadLenderCompanyId: string }>
  | TypedDataType<'set_lender_quotes_is_open', { lenderQuotesIsOpen: boolean }>
  | TypedDataType<'set_share_allocation', { shareAllocation: ShareAllocationType }>
  | TypedDataType<'set_signers', { signers: SignersType }>
  | TypedDataType<'set_template', { template: string }>
  | TypedDataType<'start_negotiation', undefined>
  | TypedDataType<'start_execution', undefined>
  | TypedDataType<'set_agreement_lock', { isLocked: boolean }>
  | TypedDataType<
      'update_agreement',
      {
        editId: string;
        doc: AgreementDocType;
        previousDoc?: AgreementDocType;
      }
    >
  | TypedDataType<
      'update_agreement_comments',
      {
        editId: string;
        doc?: AgreementDocType;
        commentChains: AgreementCommentChainType[];
        previousCommentChains?: AgreementCommentChainType[];
      }
    >
  | TypedDataType<
      'update_fa_comments',
      {
        editId: string;
        doc?: AgreementDocType;
        commentChains: AgreementCommentChainType[];
        previousCommentChains?: AgreementCommentChainType[];
      }
    >
  | TypedDataType<
      'accept_agreement',
      {
        editId: string;
        doc: AgreementDocType;
        previousDoc?: AgreementDocType;
      }
    >
  | TypedDataType<
      'accept_attributes',
      {
        names: (keyof AttributesType)[];
        values: NegotiationAttributesValuesType;
        previousValues: NegotiationAttributesValuesType;
      }
    >
  | TypedDataType<
      'reject_attributes',
      {
        names: (keyof AttributesType)[];
        values: NegotiationAttributesValuesType;
        previousValues: NegotiationAttributesValuesType;
      }
    >
  | TypedDataType<
      'save_attributes',
      {
        values: NegotiationAttributesValuesType;
        previousValues: NegotiationAttributesValuesType;
      }
    >
  | TypedDataType<'get_nda_signing_url', Record<string, unknown>>
  | TypedDataType<'signing_event', SigningEventTransitionDataType>
  | TypedDataType<'update_nda_setup', NdaSetupType>
  | TypedDataType<'cancel_loan', { loanId: string }>;

export type TransitionType = ITransition & TransitionDataUnionType & { index: number };

export type SigningEventTransitionDataType = NdaSigningEventTransitionDataType | FaSigningEventTransitionDataType;

export type NdaSigningEventTransitionDataType = TypedDataType<
  'nda',
  {
    status: 'declined' | 'completed' | 'voided';
  }
>;

export type FaSigningEventTransitionDataType = TypedDataType<
  'fa',
  {
    changedSigners: TypedSignerType[];
  }
> & {
  leadLenderCompanyId?: string;
};

export type TypedSignerType = {
  type: 'agent' | 'borrower' | 'lender' | 'securityAgent';
  signer: ISigner;
};

export type BeforeAfterType<T> = {
  before: T;
  after: T;
};

export type TermSheetStateType = {
  name: 'initial' | 'proposal' | 'accepted' | 'rejected';
  companyType: CompanyTypeType;
};

// Map from company ID to the quotes submitted by that company
export type LenderQuoteMapType = Record<string, LenderQuoteType[]>;

export type LenderQuoteType = {
  size: DecimalType;
  version: string; // The version of the contract that the quote is for
  time: DBTimeType;
};

// Map from company ID to size of allocation.
export type ShareAllocationType = Record<string, DecimalType>;

export type SignersType = {
  securityAgent: ExternalSignerType;
  agent: EmbeddedSignerType;
  borrower: EmbeddedSignerType;
  lender: EmbeddedSignerMapType;
};

export type EmbeddedSignerMapType = { [companyId: string]: EmbeddedSignerType };

// See IdentityVerifications in the DocuSign API.
export type DocuSignIdentityVerificationType = {
  identityVerification: DocuSignIdvWorkflowType[];
};

// See AccountIdentityVerificationWorkflow in the DocuSign API.
export type DocuSignIdvWorkflowType = {
  defaultDescription: string;
  defaultName: string;
  workflowId: string;
};

export type LoanCompanyType = {
  id: string;
  companyInformation: LoanCompanyInformationType;
};

export type LoanCompanyInformationType = {
  companyName: string;
  address: CompanyAddressType;
  registrationNumber: string;
  iban: string;
  bic: string;
  investorType?: InvestorType | '';
  aum: AssetUnderManagementType | '';
  type: CompanyTypeType;
};

// Map from company ID to company.
export type LoanCompanyMapType = Record<string, LoanCompanyType>;

// Map from user ID to user.
export type LoanUserMapType = Record<string, LoanUserType>;

export type TemplateType = {
  name: string; // Name of the facility agreement template
  key: string; // AWS S3 key of the object
};

export type LoadedDocumentType = {
  name: string; // Name of the document
  key: string; // AWS S3 key of the object
  url: string; // URL for download of the file
};

export type LoanUserType = {
  userId: string;
  name: string;
  companies: LoanUserCompaniesType[];
  email: string;
};

export type LoanUserCompaniesType = {
  companyId: string;
  companyInformation: LoanCompanyInformationType;
};

export type FormDisplayType = {
  visible: boolean; // True if the form should be shown
  disabled?: boolean; // True if the form is disabled
};

export const AllAttributeOperations = {
  accept: 'Accept',
  reject: 'Reject',
} as const;
export type AttributeOperationType = keyof typeof AllAttributeOperations;

export const AllAmortisationTypes = {
  bullet: 'Bullet',
  'per-annum': 'Pct. per annum',
  custom: 'Custom schedule',
} as const;
export type AmortisationTypeType = keyof typeof AllAmortisationTypes;

export type AttributesType = {
  loan_name: string;
  property_id: string;
  construction_finish_date: DBDateType;
  amortisation_frequency: InterestPeriodType;
  amortisation_partial_prepayment: AmortisationPartialPrepaymentType;
  amortisation_percentage: PercentageType;
  amortisation_type: AmortisationTypeType;
  amortisation_voluntary_cancellation_minimum: DecimalType;
  amortisation_voluntary_prepayment_minimum: DecimalType;
  applicable_law: CountryType;
  borrower_fees_arrangement_fee: number;
  borrower_fees_drop_dead_fee: DecimalType;
  borrower_fees_prepay_fees: number[];
  breakage_costs: DecimalType;
  condition_precedent: string;
  covenant_cross_default_no_event_max: DecimalType; // Currency is quantum_currency
  covenant_first_testing_date: DBDateType;
  covenant_hard_breach_clause: string;
  covenant_icr_hard: DecimalType;
  covenant_icr_soft: DecimalType;
  covenant_ltv_hard: PercentageType;
  covenant_ltv_soft: PercentageType;
  covenant_debt_yield_soft: PercentageType;
  covenant_debt_yield_hard: PercentageType;
  covenant_debt_yield_testing_cycle: CovenantTestingDatesType;
  covenant_soft_breach_prepayment: PercentageType;
  covenant_testing_dates: CovenantTestingDatesType;
  covenant_valuer: string;
  covenant_dscr_hard: DecimalType;
  covenant_dscr_soft: DecimalType;
  covenant_dscr_testing_cycle: CovenantTestingDatesType;
  covenant_icr_testing_cycle: CovenantTestingDatesType;
  covenant_liquidity_hard: DecimalType;
  covenant_liquidity_testing_cycle: CovenantTestingDatesType;
  covenant_ltv_testing_cycle: CovenantTestingDatesType;
  covenant_solvency_hard: PercentageType;
  covenant_solvency_testing_cycle: CovenantTestingDatesType;
  // TODO: New attribute for interest day count contention.
  day_count_convention: DayCountConventionType;
  expenses: DecimalType;
  guarantor_name: string;
  hedging_interest_cap_strike_percentage: number;
  hedging_interest_instrument: HedgingInterestInstrumentType;
  hedging_reqs_amount_percentage: PercentageType;
  hedging_reqs_counterparty: string;
  hedging_reqs_deadline: number; // integer
  interest_type: InterestType;
  interest_rate_base: BaseRateNameType;
  interest_rate_base_floored: boolean;
  interest_rate_level: PercentageType;
  interest_rate_margin: PercentageType;
  interest_rate_period: InterestPeriodType;
  legal_opinions: LegalOpinionType[];
  commitment_fee: number;
  lender_fees_processing_fee: PercentageType;
  // TODO: New type for representing the maturity date.
  maturity_payment_of_debt: DBDateType;
  other_conditions_change_of_control_notification: number; // integer
  other_conditions_sanctions_notification: number; // integer
  proceeds_excluded_insurance: DecimalType;
  proceeds_excluded_insurance_currency: CurrencyType;
  proceeds_excluded_recovery: DecimalType;
  proceeds_excluded_recovery_currency: CurrencyType;
  properties: LoanBasicInfoPropertyType[];
  property_address: string;
  property_original_market_value: number;
  purpose: string;
  quantum_availability_period: DBDateType;
  quantum_currency: CurrencyType;
  quantum_size: number;
  quantum_investment_size: number;
  quantum_utilisation_date: DBDateType;
  quantum_utilisation_request_days: number; // integer
  quantum_utilisation_request_time: string;
  relevant_market: RelevantMarketType;
  security_account_receivable_pledge: boolean;
  security_guarantees_other: boolean;
  security_hedging_agreement: boolean;
  security_insurance_confirmation: boolean;
  security_lease_documents: boolean;
  security_mortgage_amount: number; // Currency is quantum_currency.
  security_negative_pledge: boolean;
  security_parent_receivables: boolean;
  security_spv_share_pledge: boolean;
  transfer_fee: DecimalType;
  transfer_fee_currency: CurrencyType;
  facility_type: FacilityTypeType;
  borrower_parent_name: string;
  property_original_valuator: AllMarketValueEstByType;
  property_current_market_value_date: DBDateType;
  property_market_currency: CurrencyType;
  property_original_valuator_others: string;
  type_of_guarantee: TypeOfGuaranteeType;
  property_country: string;
  property_city: string;
  property_zip: string;
  quotation_days: number;
  covenant_gpc_requirements: string;
  covenant_gpc_testing_cycle: CovenantTestingDatesType;
  covenant_gpc_rate_offset: number;
};

export const AllAttributeKeys: (keyof AttributesType)[] = [
  'loan_name',
  'property_id',
  'construction_finish_date',
  'amortisation_frequency',
  'amortisation_partial_prepayment',
  'amortisation_percentage',
  'amortisation_type',
  'amortisation_voluntary_cancellation_minimum',
  'amortisation_voluntary_prepayment_minimum',
  'applicable_law',
  'borrower_fees_arrangement_fee',
  'borrower_fees_drop_dead_fee',
  'borrower_fees_prepay_fees',
  'breakage_costs',
  'condition_precedent',
  'covenant_cross_default_no_event_max',
  'covenant_first_testing_date',
  'covenant_hard_breach_clause',
  'covenant_icr_hard',
  'covenant_icr_soft',
  'covenant_ltv_hard',
  'covenant_ltv_soft',
  'covenant_soft_breach_prepayment',
  'covenant_testing_dates',
  'covenant_valuer',
  'covenant_dscr_hard',
  'covenant_dscr_soft',
  'covenant_dscr_testing_cycle',
  'covenant_icr_testing_cycle',
  'covenant_liquidity_hard',
  'covenant_liquidity_testing_cycle',
  'covenant_ltv_testing_cycle',
  'covenant_solvency_hard',
  'covenant_solvency_testing_cycle',
  'expenses',
  'guarantor_name',
  'hedging_interest_cap_strike_percentage',
  'hedging_interest_instrument',
  'hedging_reqs_amount_percentage',
  'hedging_reqs_counterparty',
  'hedging_reqs_deadline',
  'interest_type',
  'interest_rate_base',
  'interest_rate_base_floored',
  'interest_rate_level',
  'quotation_days',
  'interest_rate_margin',
  'interest_rate_period',
  'legal_opinions',
  'commitment_fee',
  'lender_fees_processing_fee',
  'maturity_payment_of_debt',
  'other_conditions_change_of_control_notification',
  'other_conditions_sanctions_notification',
  'proceeds_excluded_insurance',
  'proceeds_excluded_insurance_currency',
  'proceeds_excluded_recovery',
  'proceeds_excluded_recovery_currency',
  'properties',
  'property_address',
  'property_original_market_value',
  'purpose',
  'quantum_availability_period',
  'quantum_currency',
  'quantum_size',
  'quantum_investment_size',
  'quantum_utilisation_date',
  'quantum_utilisation_request_days',
  'quantum_utilisation_request_time',
  'relevant_market',
  'security_account_receivable_pledge',
  'security_guarantees_other',
  'security_hedging_agreement',
  'security_insurance_confirmation',
  'security_lease_documents',
  'security_mortgage_amount',
  'security_negative_pledge',
  'security_parent_receivables',
  'security_spv_share_pledge',
  'transfer_fee',
  'transfer_fee_currency',
  'facility_type',
  'borrower_parent_name',
  'property_original_valuator',
  'property_current_market_value_date',
  'property_market_currency',
  'type_of_guarantee',
  'day_count_convention',
];

export type PrivateDataType = {
  lender: { [companyId: string]: PrivateLenderDataType };
  faSigning?: { [envelopeId: string]: FaSigningType };
};

export type FaSigningType = {
  envelope: FaEnvelopeType;
  file: RsUploadedFileType;
};

export type PrivateLenderDataType = {
  ndaSigning: { [envelopeId: string]: NdaSigningType };
};

export type NdaSigningType = {
  // Details of who has done the signing.
  envelope: FaNdaEnvelopeType;

  // Copy of the NDA that has been signed.
  file?: RsUploadedFileType;
};
